import { Typography, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { NextPage } from "next";
import React from "react";
import { LoginLayout } from "../layouts/pages/LoginLayout";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(3),
  },
}));

const Login: NextPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <LoginLayout title="Login" displayLoginButton>
      <Typography component="h1" className={classes.heading} variant="h4">Welcome back! Please log in</Typography>
    </LoginLayout>
  );
};

export default Login;
